<template>
  <v-container fluid class="pa-0 settings">
    <Navbar v-if="window.width > 575.98" />
    <alert-status v-if="$store.state.alertStatus !== null" @close="alertClosed" />
    <v-row class="mx-0 mb-0 mt-0 mt-sm-8">
      <v-col cols="auto" class="pa-0">
        <v-navigation-drawer
          class="d-none d-md-block"
          fixed
          clipped
          fill-height
          permanent
        >
          <v-list dense shaped>
            <v-list-item-group
              class="settingsMenu"
              v-model="selectedItem"
              color="primary"
            >
              <template v-if="!isAdmin">
                <v-list-item
                  class="mt-2"
                  v-for="(item, i) in items"
                  :key="i"
                  @click="scrollMeTo(item.id)"
                >
                  <v-list-item-content>
                    <v-list-item-title class="list-item-title">{{ item.title }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
              <v-list-item @click="logoutUser">
                <v-list-item-content>
                  <v-list-item-title>LOG OUT</v-list-item-title>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-navigation-drawer>
      </v-col>
      <v-col cols="12" md="5" class="mx-auto col-md-6">
        <v-btn
          :to="{ name: isAdmin ? 'AdminUsers' : 'Calls' }"
          color="primary"
          class="mb-10 d-none pt-2 d-md-inline-block"
          outlined
        >
          <v-icon left> mdi-arrow-left</v-icon>
          Back
        </v-btn>

        <h4
          class="d-md-none d-flex align-center my-4"
          @click="$router.push({ name: isAdmin ? 'AdminUsers' : 'Calls' })"
        >
          <v-icon>mdi-chevron-left</v-icon>
          Settings
        </h4>
        <v-row class="mt-3" v-if="!isAdmin">
          <SettingsAccount
            :profile-data="profileData"
            ref="settingsAccount"
            @reloadProfile="reloadProfile"
          />
          <SettingsMessages
            :profile-data="profileData"
            ref="settingsMessages"
          />
          <SettingsCalls
            :profile-data="profileData"
            ref="settingsCalls"
            @reloadProfile="reloadProfile"
            @openLinkedNumberDialog="$refs.settingsAccount.linkDialog = true"
          />
          <SettingsNotDisturb
            :profile-data="profileData"
            ref="settingsNotDisturb"
          />
          <SettingsVoicemail
            :profile-data="profileData"
            ref="settingsVoicemail"
          />
          <SettingsWebNotifications
            :profile-data="profileData"
            ref="settingsWebNotifications"
          />
          <SettingsPayments
            :profile-data="profileData"
            ref="settingsPayments"
          />
          <SettingsSecurity
            :profile-data="profileData"
            ref="settingsSecurity"
          />
<!--           <SettingsAcessibility
            :profile-data="profileData"
            ref="settingsAcessibility"
          /> -->
          <SettingsPrivacy :profile-data="profileData" ref="settingsPrivacy" />

          <SettingsDeactivate :profile-data="profileData" ref="SettingsDeactivate" />

          <v-col cols="12" class="d-md-none mt-4 btn-logOut">
            <v-btn
              @click="$router.push('/login')"
              color="black"
              class="d-block mx-auto"
              outlined
            >
              LOG OUT
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import SettingsAccount from '@/components/Settings/SettingsAccount'
import SettingsMessages from '@/components/Settings/SettingsMessages'
import SettingsCalls from '@/components/Settings/SettingsCalls'
import SettingsNotDisturb from '@/components/Settings/SettingsNotDisturb'
import SettingsVoicemail from '@/components/Settings/SettingsVoicemail'
import SettingsWebNotifications from '@/components/Settings/SettingsWebNotifications'
import SettingsPayments from '@/components/Settings/SettingsPayments'
import SettingsSecurity from '@/components/Settings/SettingsSecurity'
// import SettingsAcessibility from '@/components/Settings/SettingsAcessibility'
import SettingsPrivacy from '@/components/Settings/SettingsPrivacy'
import SettingsDeactivate from '@/components/Settings/SettingsDeactivate'
import Navbar from '@/components/Navbar'
import AlertStatus from '@/components/Alert'
import resizeMixin from '@/mixins/resize.mixin'
import { mapActions } from 'vuex'

export default {
  name: 'Settings',
  mixins: [resizeMixin],
  components: {
    SettingsAccount,
    SettingsMessages,
    SettingsCalls,
    SettingsNotDisturb,
    SettingsVoicemail,
    SettingsWebNotifications,
    SettingsPayments,
    SettingsSecurity,
    // SettingsAcessibility,
    SettingsPrivacy,
    SettingsDeactivate,
    Navbar,
    AlertStatus
  },
  created() {
    this.getProfile()
  },
  data: () => {
    return {
      profileData: {},
      selectedItem: '',
      items: [
        { title: 'Account', id: 'settingsAccount' },
        { title: 'Messages', id: 'settingsMessages' },
        { title: 'Calls', id: 'settingsCalls' },
        { title: 'Do not disturb', id: 'settingsNotDisturb' },
        { title: 'Voicemail', id: 'settingsVoicemail' },
        { title: 'Web notifications', id: 'settingsWebNotifications' },
        { title: 'Payments', id: 'settingsPayments' },
        { title: 'Security', id: 'settingsSecurity' },
        // { title: 'Acessibility', id: 'settingsAcessibility' },
        { title: 'Privacy Policy', id: 'settingsPrivacy' }
      ]
    }
  },
  mounted () {
    if (!this.$route.params.ref) return
    this.scrollMeTo(this.$route.params.ref)
  },
  computed: {
    user () {
      return this.$store.state.user.userProfile
    },
    isAdmin () {
      return this.user && this.user.is_admin
    }
  },
  methods: {
    ...mapActions(['logout', 'getUserProfile']),
    async logoutUser () {
      try {
        await this.logout()
        // this.$router.push({ path: '/login' })
      } catch (e) {
        throw new Error(e)
      }
    },
    async getProfile () {
      try {
        const data = await this.getUserProfile()
        this.profileData = data
      } catch (err) {
        throw new Error(err)
      }
    },
    reloadProfile () {
      this.getProfile()
    },
    scrollMeTo (refName) {
      const element = this.$refs[refName].$el
      const top = element.offsetTop
      window.scrollTo(0, top)
    },
    async alertClosed (callback) {
      if (callback !== 'releaseNumber') return
      await this.getUserProfile()
      this.$router.push({ name: 'SelectNumber' }).catch(() => {})
    }
  }
}
</script>

<style>
.grey-33 {
  color: #333333;
}
</style>

<style scoped>
.settingsMenu {
  margin-top: 80px;
}

.settingsMenu .v-list-item:last-child {
  border: 2px solid red;
  background: transparent;
  border-bottom-right-radius: 10px !important;
  border-top-right-radius: 10px !important;
  width: max-content;
  margin: 14px 16px;
  border-radius: 10px;
}

.settingsMenu .v-list-item:last-child:hover {
  color: blue;
}

.v-navigation-drawer::v-deep .v-navigation-drawer__border {
  display: none;
}

.v-list-item__title {
  font-weight: 400 !important;
  color: #5F6368;
}
.v-list-item.v-list-item--active .v-list-item__title {
  font-weight: 500 !important;
  color: #5758B0;
}
</style>
