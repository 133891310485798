<template>
  <v-container fluid class="pa-0 mt-8">
    <Navbar />
    <alert-status v-if="$store.state.alertStatus !== null" />
    <v-row class="ma-0">
      <v-col cols="12" md="5" class="mx-auto px-5">
        <v-btn
          :to="{ name: 'Settings' }"
          color="primary"
          class="mb-10"
          outlined
        >
          <v-icon left> mdi-arrow-left</v-icon>
          Back to settings
        </v-btn>
        <h1 class="font-weight-medium mb-4">
          Tell FreeFone how to forward your calls
        </h1>

        <p class="body-1">
          Choose how to forward calls from anonymous callers or from people
          and groups in Freefone Contacts. You can also play different voicemail
          greetings or selectively screen their calls.
        </p>

        <v-card :loading="loading" class="mt-8" :style="{ 'min-height': loading ? '4px' : 0 }">
          <v-list v-if="!loading && ruleList.length">
            <v-list-item v-for="(rule, index) in ruleList" :key="index" class="px-3">
              <v-list-item-avatar
                class="me-3 mr-3 d-flex justify-center align-center"
              >
                <v-icon color="#5758b0" style="background: #e4edfa">mdi-account</v-icon>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title class="font-weight-bold text-capitalize">{{ rule.number_type.replace('_', ' ') }}</v-list-item-title>

                <!-- <v-list-item-subtitle class="caption">The callers hide their numbers and name</v-list-item-subtitle> -->
              </v-list-item-content>

              <v-btn
                class="px-1 font-weight-medium transparentButton"
                @click="$router.push({ name: 'EditRule', params: { uuid: rule.uuid } })"
              >
                <v-icon color="#637381">mdi-pencil</v-icon>
              </v-btn>

              <v-btn
                @click="openDeleteDialog(rule.uuid)"
                class="px-1 py-6 font-weight-medium transparentButton"
              >
                <v-icon color="#637381">mdi-delete</v-icon>
              </v-btn>
            </v-list-item>
          </v-list>
        </v-card>
      </v-col>
    </v-row>
    <v-row
      class="ma-0"
      align="center"
      justify="center"
    >
      <div class="mx-auto d-flex justify-center flex-column align-center">
        <div class="text-center" style="max-width: 580px">
          <div class="mt-6">
            <v-btn
              class="blueButton caption text-capitalize"
              color="white"
              width="147px"
              elevation="0"
              :outlined="$vuetify.breakpoint.smAndUp"
              :block="$vuetify.breakpoint.xs"
              @click="$router.push({ name: 'AddRule' })"
            >
              <v-icon left color="white">mdi-plus</v-icon>
              Create a rule
            </v-btn>
          </div>
        </div>
      </div>
    </v-row>
    <v-dialog v-model="deleteDialog" width="500">
      <v-card>
        <v-card-title class="body-1">
          Delete
          <v-spacer></v-spacer>
          <v-btn @click="closeDeleteDialog" small icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>

        <v-card-text>Delete rule?</v-card-text>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            small
            color="primary"
            class="px-5 py-5"
            elevation="0"
            outlined
            @click="closeDeleteDialog"
            >Cancel
          </v-btn>
          <v-btn
            small
            color="danger"
            class="px-5 py-5 white--text"
            elevation="0"
            @click="removeRule"
            >Delete
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import Navbar from '@/components/Navbar'
import AlertStatus from '@/components/Alert'
import { mapActions } from 'vuex'

export default {
  name: 'ManageRules',
  components: {
    Navbar,
    AlertStatus
  },
  data: () => {
    return {
      loading: false,
      ruleList: [],
      total: 0,
      deleteDialog: false,
      deleteUuid: ''
    }
  },
  created () {
    this.loadRules()
  },
  methods: {
    ...mapActions(['getRuleList', 'showAlert', 'deleteRule']),
    async loadRules () {
      try {
        this.loading = true
        const data = await this.getRuleList()
        this.ruleList = data.data
        this.total = data.total
      } catch (err) {
        throw new Error(err)
      } finally {
        this.loading = false
      }
    },
    openDeleteDialog (uuid) {
      this.deleteUuid = uuid
      this.removeRule()
      // this.deleteDialog = true
    },
    closeDeleteDialog () {
      this.deleteDialog = false
      this.deleteUuid = ''
    },
    async removeRule() {
      try {
        if (!this.deleteUuid) return
        const data = await this.deleteRule(this.deleteUuid)
        if (!data) return
        this.showAlert({ message: 'Rule has been deleted successfully' })
        this.closeDeleteDialog()
        this.loadRules()
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
        throw new Error(err)
      }
    }
  }
}
</script>

<style>
.blueButton {
  background-color: #5758b0 !important;
  border-color: #5758b0 !important;
}

.transparentButton {
  background: transparent !important;
  box-shadow: none !important;
}
</style>
