<template>
  <v-menu
    v-model="menu"
    ref="menu"
    :close-on-content-click="false"
    :return-value.sync="menu"
    transition="scale-transition"
    offset-y
    bottom
    left
    min-width="auto"
    class="menu-mobile"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        solo
        outlined
        hide-details
        v-bind="attrs"
        v-on="on"
        class="grey-button px-2"
      >
        <span class="mr-auto">{{ date.toString() }}</span>
        <v-icon size="14px" color="#637381" class="ml-2">mdi-chevron-down</v-icon>
      </v-btn>
    </template>
    <div class="d-flex">
      <v-date-picker
        v-model="date"
        :picker-date.sync="pickerDate"
        :max="disableFuture ? today : ''"
        no-title
        scrollable
        @change="menu = false"
      />
    </div>
  </v-menu>
</template>

<script>
import moment from 'moment'
export default {
  name: 'DatePicker',
  props: {
    disableFuture: { type: Boolean, default: false }
  },
  data: () => ({
    pickerDate: null,
    date: moment(new Date()).format('YYYY-MM-DD'),
    menu: false
  }),
  computed: {
    today () {
      return moment(new Date()).format('YYYY-MM-DD')
    }
  },
  watch: {
    date (val) {
      if (new Date(this.date) > new Date()) this.date = this.today
      this.$emit('changeDate', val)
    },
    menu (val) {
      const today = new Date().toISOString().split('T')[0]
      this.pickerDate = today
    }
  }
}
</script>

<style scoped lang="scss">
.v-menu__content {
  @media (max-width: 959px) {
    right: 0 !important;
    left: 0 !important;
    overflow-x: auto !important;
    max-width: max-content !important;
    margin-top: 12px !important;
  }
}

.v-picker::v-deep .v-date-picker-table {
  table {
    border-collapse: collapse !important;
  }
  td {
    border: 1px solid #e4e4e4 !important;
  }
  tbody td {
    .v-btn {
      border-radius: 0 !important;
      width: 100% !important;
      color: #6770a1 !important;
      &:focus, &:hover {
        border-radius: 0 !important;
      }
      &.v-btn--disabled .v-btn__content {
        color: gray !important;
      }
    }
    .v-btn--active {
      &.v-date-picker-table__current {
        background-color: #6170C5 !important;
        color: white !important;
      }
      background-color: #6161c547 !important;
      border-radius: 0 !important;
    }
  }
}
</style>
