<template>
  <v-container class="my-auto">
    <v-row v-if="!responseAlert">
      <v-col cols="12" md="6" class="mx-auto d-flex flex-column align-center">
        <h4 class="pageTitle display-1 text-center dark--text">Registration</h4>
        <v-form
          ref="form"
          v-model="valid"
          class="mt-10 dark--text registration-form"
          lazy-validation
        >
          <v-layout align-center class="mb-2">
            <p class="body-1 mb-0">First Name</p>
            <p class="ms-auto caption mb-0 muted--text">Mandatory</p>
          </v-layout>
          <v-text-field
            class="mb-4 inputStyle"
            background-color="#fafafa"
            v-model="form.first_name"
            :rules="firstNameRules"
            flat
            outlined
            solo
            required
            @keypress="onlyLetters"
            @keypress.enter.prevent="enterHandler"
            ></v-text-field>

          <v-layout align-center class="mb-2">
            <p class="body-1 mb-0">Last Name</p>
            <p class="ms-auto caption mb-0 muted--text">Mandatory</p>
          </v-layout>
          <v-text-field
            class="mb-4 inputStyle"
            background-color="#fafafa"
            v-model="form.last_name"
            :rules="lastNameRules"
            flat
            outlined
            solo
            required
            @keypress="onlyLetters"
            @keypress.enter.prevent="enterHandler"
            ></v-text-field>

          <v-layout align-center class="mb-2">
            <p class="body-1 mb-0">Email</p>
            <p class="ms-auto caption mb-0 muted--text">Mandatory</p>
          </v-layout>
          <v-text-field
            class="mb-4 inputStyle"
            background-color="#fafafa"
            v-model="form.email"
            :rules="emailRulesDynamic"
            autocomplete="new-email"
            type="email"
            flat
            outlined
            solo
            required
            @keypress.enter.prevent="enterHandler"
            @blur="validate"
            @focus="clearRules"
            @input="clearRules"
          ></v-text-field>

          <v-layout align-center class="mb-2 mt-0">
            <p class="body-1 mb-0">Mobile Number</p>
            <p class="ms-auto caption mb-0 muted--text">Mandatory</p>
          </v-layout>
          <v-text-field
            v-model.number="form.mobile_number"
            :rules="mobileRules"
            :maxlength="maxlength"
            autocomplete="new-phone"
            type="text"
            class="mb-4 inputStyle"
            background-color="#fafafa"
            flat
            outlined
            solo
            required
            @paste.prevent="onPasteNumber"
            @keypress="onlyNumbers"
            @keypress.enter.prevent="enterHandler"
          ></v-text-field>

          <v-layout align-center class="mb-2 mt-0">
            <p class="body-1 mb-0">Password</p>
            <p class="ms-auto caption mb-0 muted--text">Mandatory</p>
          </v-layout>
          <v-text-field
            v-model="form.password"
            :rules="passwordRules"
            :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
            :type="show1 ? 'text' : 'password'"
            autocomplete="new-password"
            name="input-10-1"
            class="mb-4 inputStyle"
            background-color="#fafafa"
            outlined
            solo
            flat
            @click:append="show1 = !show1"
            @keypress.enter.prevent="enterHandler"
          ></v-text-field>

          <v-btn
            :disabled="disableRegister"
            color="primary"
            class="mt-3 py-6 text-capitalize"
            block
            @click="register"
          >
            Register
          </v-btn>

          <p class="caption muted--text mt-6 pb-2 text-center">Or</p>

          <social-auth />

          <p class="mt-8 muted--text d-flex align-center justify-center fz-16 py-0" style="height:20px">
            Already have an account?
            <v-btn
              text
              :to="{ name: 'Login' }"
              class="info--text px-1 text-capitalize py-0"
              style="font-size:16px"
              height="20px"
              plain
              :ripple="false"
            >
              Sign in
            </v-btn>
          </p>
        </v-form>
      </v-col>
    </v-row>

    <v-row v-else class="d-flex justify-center">
      <div class="w-100 px-4">
        <v-btn
          v-if="status && showConfirmInput"
          color="primary"
          class="my-10"
          outlined
          @click="back"
        >
          <v-icon left> mdi-arrow-left</v-icon>
          Back
        </v-btn>
      </div>
      <div class="numberSection text-center">
        <v-img
          :src="require('../assets/' + responseAlertImage)"
          contain
          width="40"
          height="40"
          max-width="min-content"
          class="mb-6 mx-auto"
        />
        <h4 class="dark--text verifySuccess">{{ responseAlertMessage }}</h4>
        <v-card v-if="showConfirmInput && status" class="mt-4 mx-auto" style="width: min(500px, 90vw);">
          <v-card-title class="body-1">
            Enter the Verification Code
          </v-card-title>

          <div class="px-8 py-4">
            <v-otp-input
              v-model="confirmCode"
              :class="{ 'error--text': confirmCodeError }"
              length="6"
              @keypress="onlyNumbers"
            />
            <p v-if="confirmCodeError" class="red--text w-100 ma-0 text-left fz-12">Incorrect verification code</p>
          </div>
          <p>You haven't received code?
            <a @click="resendCode" class="text-decoration-none info--text">Re-send code</a>
          </p>
        </v-card>
        <p v-if="codeSent" class="text-center grey--text" style="position: absolute; left: 50%; transform: translateX(-50%);">Code sent to email.</p>
        <v-btn
          :disabled="status && showConfirmInput && confirmCode.length < 6"
          color="primary"
          class="mt-12 text-none"
          depressed
          @click="actionDetect"
        >
          {{ status ? (showConfirmInput ? 'Verify' : 'Login') : 'Register' }}
        </v-btn>
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import SocialAuth from '@/components/SocialAuth'

export default {
  name: 'Registration',
  components: { SocialAuth },
  data: () => ({
    responseAlert: false,
    responseAlertImage: '',
    responseAlertMessage: '',
    status: false,
    valid: true,
    firstNameRules: [
      (v) => !!v || 'First Name is required',
      (v) => (v && v.length <= 20) || 'First Name must be less than 20 characters'
    ],
    lastNameRules: [
      (v) => !!v || 'Last Name is required',
      (v) =>
        (v && v.length <= 20) || 'Last Name must be less than 20 characters'
    ],
    emailRules: [
      (v) => !!v || 'E-mail is required',
      (v) => /^\w+([+.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) || 'E-mail must be valid'
    ],
    emailRulesDynamic: [],
    mobileRules: [
      v => !!v || 'Phone number is required',
      v => /^[+]?[(]?[0-9]{3}[)]?[-\s.]?[0-9]{3}[-\s.]?[0-9]{4,6}$/im.test(v) || 'Phone number must be valid'
    ],
    passwordRules: [
      (v) => !!v || 'Password is required',
      (v) =>
        (v &&
          v.length >= 8 &&
          v.length <= 32 &&
          /[A-Z]/.test(v) &&
          /[a-z]/.test(v) &&
          /\d/.test(v) &&
          /[#?!.,:;'"|\\@$%^&*/+=-]/.test(v)) ||
        'Enter 8 to 32 characters, including 1 number, 1 lower case letter, 1 uppercase letter and 1 special character'
    ],

    show1: false,
    companyName: '',
    companyRules: [(v) => !!v || 'Company Name is required'],
    form: {
      first_name: '',
      last_name: '',
      email: '',
      mobile_number: '',
      password: ''
    },
    showConfirmInput: true,
    confirmCode: '',
    codeSent: false,
    userUuid: '',
    confirmCodeError: false,
    maxlength: 11
  }),
  computed: {
    disableRegister () {
      return !this.valid ||
             !this.form.first_name ||
             !this.form.last_name ||
             !this.form.email ||
             !this.form.password
    }
  },
  methods: {
    ...mapActions(['registration', 'confirmEmail', 'reSendConfirmCode']),
    async actionDetect () {
      if (this.status) {
        this.confirmCodeError = false
        if (!this.showConfirmInput) return this.$router.push('Login')
        try {
          const data = await this.confirmEmail(this.confirmCode)
          if (!data) return
          this.responseAlertMessage = 'User was confirmed successfully'
          this.responseAlertImage = 'svg/verify-success.svg'
          this.showConfirmInput = false
          setTimeout(() => {
            this.$router.push({ name: 'Login' })
          }, 30000)
        } catch (err) {
          this.confirmCodeError = true
          throw new Error(err)
        }
      } else {
        this.responseAlert = false
      }
    },
    async register () {
      try {
        const data = await this.registration(this.form)
        this.userUuid = data.data.user_uuid
        this.responseAlert = true
        this.status = true
        this.showConfirmInput = true
        this.responseAlertMessage = 'Confirmation code was sent to your email'
        this.responseAlertImage = 'svg/verify-success.svg'
      } catch (err) {
        this.responseAlert = true
        this.status = false
        this.responseAlertMessage = err.message
        this.responseAlertImage = 'png/error.png'
        throw new Error(err)
      }
    },
    async verify() {
      try {
        await this.confirmEmail(this.$route.params.token)
        this.responseAlertMessage = 'User was confirmed successfully'
        this.responseAlertImage = 'svg/verify-success.svg'
      } catch (err) {
        this.responseAlertMessage = err
        this.responseAlertImage = 'png/error.png'
      }
    },
    async resendCode () {
      try {
        if (!this.userUuid) return
        this.confirmCode = ''
        this.confirmCodeError = false
        const data = await this.reSendConfirmCode(this.userUuid)
        this.codeSent = true
        setTimeout(() => {
          this.codeSent = false
        }, 2000)
      } catch (err) {
        const error = (Array.isArray(err.message)) ? err.message[0] : err.message
        this.showAlert({ error })
      }
    },
    back () {
      this.responseAlert = false
      this.responseAlertImage = ''
      this.responseAlertMessage = ''
      this.status = false
      this.valid = true
      this.show1 = false
      this.form = {
        first_name: '',
        last_name: '',
        email: '',
        mobile_number: '',
        password: ''
      }
      this.showConfirmInput = true
      this.confirmCode = ''
      this.codeSent = false
      this.userUuid = ''
      this.confirmCodeError = false
    },
    validate () {
      this.setRules()
      this.compute()
    },
    setRules () {
      this.emailRulesDynamic = this.emailRules
    },
    compute () {
      try {
        this.$emit('input', this.form.email)
      } catch (e) {
        console.log('compute error', e)
      }
    },
    clearRules () {
      this.emailRulesDynamic = []
    },
    onlyNumbers (event) {
      if (!/\d/.test(event.key)) return event.preventDefault()
    },
    onlyLetters (event) {
      if (!/^[A-Za-z-.']+$/.test(event.key)) return event.preventDefault()
    },
    enterHandler () {
      if (this.disableRegister) return this.validate()
      this.register()
    },
    onPasteNumber (evt) {
      const text = evt.clipboardData.getData('text')
      let filter = text.replace(/[^\d]/g, '')
      if (filter.length > this.maxlength) {
        filter = filter.substring(0, this.maxlength)
      }
      this.form.mobile_number = filter
    }
  }
}
</script>

<style scoped lang="scss">
.pageTitle {
  margin-top: 120px;
}
.inputStyle {
  height: 76px;
}
.registration-form {
  width: min(560px, 90vw);
}
.error--text ::v-deep .v-input__slot fieldset {
  border-color: #ff0000;
}
.v-text-field::v-deep {
  &:not(.error--text) {
    .v-input__slot {
      height: 60px;
    }
    .v-input__slot fieldset {
      border: 1px solid #e3e3e3 !important;
    }

    &.v-input--is-dirty {
      .v-input__slot fieldset {
        border: 1px solid #27ae60 !important;
      }

      &.v-input--is-focused {
        .v-input__slot fieldset {
          border: 1px solid #5758b0 !important;
        }
      }
    }
  }
}

.v-btn--outlined.light--text {
  border: 1px solid #d6d8e3;
  width: 100%;
}

.inputStyle::v-deep {
  .v-icon::before {
    color: #E3E3E3 !important;
  }
}
</style>
